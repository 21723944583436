import { Icon } from '@blastradius/ui';
import classNames from 'classnames';
import React from 'react';
import {
  Incident,
  IncidentStatuses,
  IncidentSteps,
} from '@customer-web-app/domains/incidents/models/incident';
import getIncidentStep from '@customer-web-app/domains/shared/services/get-incident-step';
import incidentTypeIconMapper from '@customer-web-app/domains/incident-details/mappers/incident-type-icon-mapper';

type Sizes = 'regular' | 'large';

type Props = {
  incident: Incident;
  withPill?: boolean;
  livePillBackground?: string;
  liveIconColor?: string;
  loading?: boolean;
  size?: Sizes;
};

const iconsMapper: {
  [key in IncidentSteps]: {
    name: React.ComponentProps<typeof Icon>['name'];
    fill: string;
  };
} = {
  opened: {
    name: 'incidents',
    fill: 'fill-pink-500',
  },
  pending: {
    name: 'incident-pending-simple',
    fill: 'fill-gray-500 dark:fill-white',
  },
  remediated: {
    name: 'incident-remediated-simple',
    fill: 'fill-green-500',
  },
  closed: {
    name: 'incident-closed-simple',
    fill: 'fill-gray-500',
  },
  onHold: {
    name: 'incident-on-hold',
    fill: 'fill-pink-500',
  },
};

const IncidentDetailsIconContained: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({
  incident,
  withPill = false,
  loading = false,
  livePillBackground = 'bg-gray-100 dark:bg-white',
  liveIconColor = 'fill-gray-900',
  size = 'large',
  ...props
}) => {
  const isIncidentLive =
    incident?.incidentStatus === IncidentStatuses.Opened && incident?.isLive;

  const iconProperties = iconsMapper[getIncidentStep(incident)];

  const defaultClassName =
    'w-13 h-13 2xl:w-14 2xl:h-14 rounded p-3 flex items-center justify-center relative';

  const className = classNames(defaultClassName, props.className, {
    'w-12 h-12 2xl:w-16 2xl:h-16': size === 'large',
    'w-13 h-13 2xl:w-14 2xl:h-14': size === 'regular',
    'bg-gray-100 dark:bg-gray-950': !isIncidentLive,
    'bg-pink-500': isIncidentLive,
  });

  return (
    <div {...props} className={className}>
      {!loading && incident?.incidentType && (
        <Icon
          name={incidentTypeIconMapper?.[incident.incidentType]}
          size={26}
          className={classNames(iconProperties?.fill, {
            'w-6 h-6 2xl:w-8 2xl:h-8': size === 'large',
            'w-6 h-6 2xl:w-7 2xl:h-7': size === 'regular',
            '!fill-yellow-500': incident?.closedAsFalsePositive,
            '!fill-white': isIncidentLive,
          })}
        />
      )}

      {withPill && (
        <div
          className={classNames(
            `w-6 h-6 rounded-full absolute flex items-center
          justify-center -top-2 -right-2`,
            {
              'bg-gray-100 dark:bg-gray-950 border-2 border-white dark:border-gray-900':
                !isIncidentLive,
              [livePillBackground]: isIncidentLive,
            },
          )}
          data-testid="incident-contained-icon-pill"
        >
          {!loading && (
            <Icon
              name={iconProperties?.name}
              size={14}
              className={classNames('-mt-px', {
                'fill-gray-900 dark:fill-white': !isIncidentLive,
                [liveIconColor]: isIncidentLive,
              })}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default IncidentDetailsIconContained;
